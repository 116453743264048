.pan-text {
  font-size: 18px;
}

.pan-label {
  color: $dark-color;
  margin-bottom: auto;
}

.pan-value {
  color: $black;
  margin-bottom: auto;
}

.pan-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}